<template>
  <div>
    <div class="form-row">
      <div class="col-12">
        <div class="form-group">
          <label>Data:</label>
          <date-picker v-model="filter.created_at" />
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col-12">
        <div class="form-group">
          <label>Origem:</label>
          <history-sources v-model="filter.source" />
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col-12">
        <div class="form-group">
          <label>Usuário:</label>
          <history-users v-model="filter.user_id" />
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col-12">
        <div class="form-group">
          <label>Texto:</label>
          <input type="text" class="form-control" v-model="filter.text" />
        </div>
      </div>
    </div>

    <filter-buttons @restartFilter="restartFilter" @cancel="cancel" @filter="filterData" />
  </div>
</template>
<script type="text/javascript">
import FilterButtons from '@/theme/buttons/FilterButtons.vue';
import functions from '@/functions';
import DatePicker from '@/theme/components/DatePicker.vue';
import HistorySources from './HistorySources.vue';
import HistoryUsers from './HistoryUsers.vue';

export default {
  name: 'history_filter',
  props: {
    filterParm: {
      type: Object,
    },
  },
  components: {
    FilterButtons,
    DatePicker,
    HistorySources,
    HistoryUsers,
  },
  data() {
    return {
      filter: {
        created_at: null,
        source: null,
        user_id: null,
        text: null,
      },
    };
  },
  methods: {
    setInitialFilter() {
      this.resetFilterObject();
      if (this.filterParm) this.getFilterFromParm();
    },
    getFilterFromParm() {
      const filterKeys = Object.keys(this.filterParm);
      filterKeys.forEach((key) => {
        this.filter[key] = '';
      });
    },
    resetFilterObject() {
      this.filter = functions.cleanObjectProperties(this.filter);
    },
    restartFilter() {
      this.resetFilterObject();
      this.filterData();
    },
    filterData() {
      this.$emit('filter', this.filter);
    },
    cancel() {
      this.$emit('cancel');
    },
  },
  watch: {
    'filterParm.created_at': {
      handler() {
        this.filter.created_at = this.filterParm.created_at;
      },
    },
    'filterParm.source': {
      handler() {
        this.filter.source = this.filterParm.source;
      },
    },
    'filterParm.user_id': {
      handler() {
        this.filter.user_id = this.filterParm.user_id;
      },
    },
    'filterParm.text': {
      handler() {
        this.filter.text = this.filterParm.text;
      },
    },
  },
  mounted() {
    this.setInitialFilter();
  },
};
</script>
