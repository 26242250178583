<template>
  <content-box title="Histórico" icon="fa-history">
    <toggable-box title="Seleção" size="sm" v-model="filter_box_opened">
      <history-filter :filterParm="filter" @filter="filter_data" @cancel="close_filter" />
    </toggable-box>
    <div class="row">
      <div class="col right">
        <open-filter-button @open_filter="filter_box_opened = true" />
      </div>
    </div>
    <div v-if="history.length == 0">
      <alert>Não há histórico que satisfaça a condição.</alert>
    </div>
    <div v-else>
      <div class="show_on_pc">
        <div v-for="history_item in history" :key="history_item.id">
          <history-item :history_date="history_item.created_at">
            <title-box>{{ history_item.user_name | historyUserName }}</title-box>
            <div class="history_info">
              <span class="history_customer" v-if="history_item.charge_id">
                <i class="fa fa-user"></i>
                <span class="history_customer_name">{{ history_item.customer_name }}</span>
                <span class="history_customer_email"> ({{ history_item.customer_email }})</span>
              </span>
              <span class="history_detail">{{ history_item.text }}</span>
            </div>
          </history-item>
        </div>
      </div>
    </div>
    <paginator :current="filter.page" :pages="filter.pages" @paginate="paginate" />
  </content-box>
</template>
<script type="text/javascript">
import { mapState, mapActions, mapMutations } from 'vuex';
import HistoryItem from '@/theme/components/HistoryItem.vue';
import TitleBox from '@/theme/boxes/TitleBoxInsideContainers.vue';
import ContentBox from '@/theme/boxes/ContentBox.vue';
import Paginator from '@/features/paginator/Paginator.vue';
import ToggableBox from '@/theme/boxes/ToggableBox.vue';
import OpenFilterButton from '@/theme/buttons/OpenFilterButton.vue';
import dateHandler from '@/functions/dateHandler/dateHandler';
import Alert from '@/theme/components/Alert.vue';
import HistoryFilter from './HistoryFilter.vue';
import filters from '../filters';

export default {
  filters,
  components: {
    HistoryItem,
    TitleBox,
    ContentBox,
    Paginator,
    HistoryFilter,
    ToggableBox,
    OpenFilterButton,
    Alert,
  },
  data() {
    return {
      processing: false,
      filter_box_opened: false,
    };
  },
  methods: {
    ...mapActions('histories', [
      'ActionlistHistory',
      'ActionListHistoryPaginate',
      'ActionListHistoryFilter',
    ]),
    ...mapMutations({
      setLinesPerPage: 'histories/SET_LINES_PER_PAGE',
    }),
    async load_history() {
      this.setLinesPerPage(20);
      this.ActionlistHistory();
    },
    async paginate(page) {
      this.processing = true;
      this.ActionListHistoryPaginate(page);
      this.processing = false;
    },
    close_filter() {
      this.filter_box_opened = false;
    },
    async filter_data(filter) {
      try {
        await this.ActionListHistoryFilter({ ...filter });
      } catch (error) {
        console.log(error);
      } finally {
        this.close_filter();
      }
    },
    initial_filter() {
      return {
        created_at: dateHandler.format(null, 'YYYY-MM-DD'),
        source: null,
        user_id: null,
        text: null,
      };
    },
    async load_with_initial_filter() {
      await this.ActionListHistoryFilter(this.initial_filter());
    },
  },
  computed: {
    ...mapState('histories', ['history', 'filter']),
  },
  async mounted() {
    this.processing = true;
    await this.load_with_initial_filter();
    this.processing = false;
  },
};
</script>
<style type="text/css" scoped>
.history_info {
  padding: 5px;
}
.history_customer i {
  font-size: 1rem;
  color: var(--standard-color);
  margin-right: 10px;
}
.history_customer {
  display: block;
  margin-bottom: 5px;
}
.history_customer_name {
  color: var(--standard-color);
}
.history_customer_email {
  color: var(--standard-color);
}
</style>
