<template>
  <general-selector :items="sources" v-model="source_selected" />
</template>
<script type="text/javascript">
import GeneralSelector from '@/components/Selectors/GeneralSelector.vue';
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    GeneralSelector,
  },
  props: ['value'],
  data() {
    return {
      source_selected: '',
    };
  },
  methods: {
    ...mapActions('histories', ['ActionListHistorySources']),
  },
  computed: {
    ...mapState('histories', ['history_sources']),
    sources() {
      const sourcesForSelector = this.history_sources.map((source) => ({
        key: source.slug,
        value: source.name,
      }));
      return [{ key: '', value: 'Todas' }, ...sourcesForSelector];
    },
  },
  watch: {
    value: {
      handler() {
        this.source_selected = this.value;
      },
    },
    source_selected: {
      handler() {
        this.$emit('input', this.source_selected);
      },
    },
  },
  async mounted() {
    await this.ActionListHistorySources();
  },
};
</script>
