<template>
  <general-selector :items="users" v-model="user_selected" />
</template>
<script type="text/javascript">
import GeneralSelector from '@/components/Selectors/GeneralSelector.vue';
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    GeneralSelector,
  },
  props: ['value'],
  data() {
    return {
      user_selected: '',
    };
  },
  methods: {
    ...mapActions('histories', ['ActionListHistoryUsers']),
  },
  computed: {
    ...mapState('histories', ['history_users']),
    users() {
      const usersForSelector = this.history_users.map((user) => ({
        key: user.user_id,
        value: user.name,
      }));
      return [{ key: '', value: 'Todos' }, ...usersForSelector];
    },
  },
  watch: {
    value: {
      handler() {
        this.user_selected = this.value;
      },
    },
    user_selected: {
      handler() {
        this.$emit('input', this.user_selected);
      },
    },
  },
  async mounted() {
    await this.ActionListHistoryUsers();
  },
};
</script>
